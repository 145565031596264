import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../../config";
import { SiPinterest } from "react-icons/si";
import Switch from "react-switch";

function HomePage() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAllGames, setShowAllGames] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGames();
  }, [eventId]);

  const fetchGames = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/live_games`
      );
      if (response.ok) {
        const data = await response.json();
        setGames(data.games); // Zugriff auf das `games`-Array im Event-Datenformat
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) return; // Warte, bis die initialen Spieldaten geladen sind

    let socket;
    const connectWebSocket = () => {
      const wsUrl = `ws://localhost:8000/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected for game:");
        // setError(null);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("mesage");
        if (data.action === "reload") {
          fetchGames();
          return;
        }
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
        // setError("Verbindungsfehler. Bitte später erneut versuchen.");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const handleChange = () => {
    setShowAllGames(!showAllGames);
  };

  const handleSingleGameClick = (gameId) => {
    navigate(`/live/game?gameId=${gameId}&liveticker=true`);
  };

  const handleAllGamesClick = () => {
    navigate(`/live/${eventId}/liveGames`);
  };

  const GameCard = ({ gameProp: game }) => {
    return (
      <button
        onClick={() => handleSingleGameClick(game.id)}
        className={`w-full  text-white py-2 rounded hover:bg-blue-600 ${
          game.status === "aktiv" ? "bg-green-500" : "bg-blue-500"
        }`}
      >
        {game.team1} {game.legscore_team1} vs {game.legscore_team2} {game.team2}{" "}
        - {game.round.description} {game.round.group || ""}
      </button>
    );
  };

  if (loading) return <div>Lädt Spiele...</div>;

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-3xl font-semibold text-center">
        Willkommen im Darts Live-Viewer
      </h1>

      <div className="space-y-4">
        <div className="flex flex-row flex-wrap gap-4 items-baseline">
          <h2 className="text-2xl font-semibold">Einzelnes Spiel verfolgen</h2>
          <div className="flex flex-row gap-4 items-center">
            <h3 className="text-xl">
              {showAllGames ? "Live Spiele anzeigen" : "Alle Spiele anzeigen"}
            </h3>
            <Switch
              onChange={handleChange}
              checked={showAllGames}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>

        {games.length > 0 ? (
          <ul className="space-y-2">
            {games.map((game) => (
              <li key={game.id}>
                {showAllGames ? (
                  <GameCard gameProp={game}></GameCard>
                ) : (
                  game.status === "aktiv" && (
                    <GameCard gameProp={game}></GameCard>
                  )
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>Derzeit sind keine Spiele verfügbar.</p>
        )}
      </div>

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">
          Alle aktuellen Spiele verfolgen
        </h2>
        <button
          onClick={handleAllGamesClick}
          className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
        >
          Alle Spiele anzeigen
        </button>
      </div>

      {/* <div className="space-y-4">
        <h2 className="text-2xl font-semibold">
          Alle Gruppen Spiele verfolgen
        </h2>
        <button
          onClick={handleAllGamesClick}
          className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
        >
          Alle Spiele anzeigen
        </button>
      </div> */}
    </div>
  );
}

export default HomePage;
