import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../../config";
import GameViewer from "./GameViewer";

function LiveGames() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchGames();
  }, [eventId]);

  const fetchGames = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/live_games`
      );
      if (response.ok) {
        const data = await response.json();
        setGames(data.games);
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let socket;
    const connectWebSocket = () => {
      const wsUrl = `ws://localhost:8000/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected to:", wsUrl);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === "reload") {
          fetchGames();
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected. Attempting to reconnect...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [eventId]);

  if (loading) return <div>Lädt Spiele...</div>;

  return (
    <div className=" space-y-2">
      <h1 className="text-3xl font-semibold text-center mt-4">
        Alle aktuellen Spiele
      </h1>
      {games.length > 0 ? (
        <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3 place-content-around">
          {games.map((game) => (
            <div key={game.id} className="">
              <GameViewer gameId={game.id} liveticker={false} />
            </div>
          ))}
        </div>
      ) : (
        <p>Derzeit sind keine Spiele verfügbar.</p>
      )}
    </div>
  );
}

export default LiveGames;
