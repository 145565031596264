import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function EventEdit() {
  let { eventId } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (eventId) {
      const fetchEvent = async () => {
        const response = await fetch(`${BACKEND_URL}/events/${eventId}`);
        const data = await response.json();
        setEvent(data);
      };

      fetchEvent();
    }
  }, [eventId]);

  const releaseEvent = async () => {
    // Überprüfen, ob alle Boards eine Beschreibung haben und mindestens eine Runde zugeordnet wurde
    const allBoardsReady = event.boards.every(
      (board) => board.description && board.rounds && board.rounds.length > 0
    );

    if (!allBoardsReady) {
      alert(
        "Alle Boards benötigen eine Beschreibung und mindestens eine zugeordnete Runde."
      );
      return;
    }

    // Event als bereit markieren
    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/release_event`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      alert("Das Event wurde erfolgreich freigegeben!");
      setEvent((prevEvent) => ({ ...prevEvent, event_ready: true }));
    } else {
      alert("Fehler beim Freigeben des Events.");
    }
  };

  if (!event) return <div>Lädt...</div>;

  return (
    <div className="p-4 max-w-lg mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center">Turnierdetails</h2>
      <div className="border rounded-lg p-4 shadow-md bg-white">
        <h3 className="text-xl font-semibold mb-2">Allgemeine Informationen</h3>
        <p>
          <strong>Turniername:</strong> {event.name}
        </p>
        <p>
          <strong>Datum:</strong> {event.date}
        </p>
        <p>
          <strong>Anzahl Gruppen:</strong> {event.group_count}
        </p>
        <p>
          <strong>Teams pro Gruppe:</strong> {event.group_team_count}
        </p>
        <p>
          <strong>Spieler pro Team:</strong> {event.team_player_count}
        </p>
        <p>
          <strong>Hauptrunden:</strong> {event.mainround_count}
        </p>
        <p>
          <strong>Vereinsspieler erlaubt:</strong>{" "}
          {event.allow_club_players ? "Ja" : "Nein"}
        </p>
        {event.allow_club_players && (
          <p>
            <strong>Max. Vereinsspieler pro Team:</strong>{" "}
            {event.max_club_players}
          </p>
        )}
        <p>
          <strong>Event bereit:</strong> {event.event_ready ? "Ja" : "Nein"}
        </p>
        <p>
          <strong>Anzahl der Boards:</strong> {event.board_count}
        </p>
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">Rundenübersicht</h3>
      <div className="space-y-4">
        {event.rounds &&
          event.rounds.map((round, index) => (
            <div
              key={index}
              className="border rounded-lg p-4 shadow-md bg-white"
            >
              <p>
                <strong>{round.description}:</strong>
              </p>
              <p>
                <strong>Gewinn-Legs:</strong> {round.legs}
              </p>
              <p>
                <strong>Modus:</strong> {round.mode}
              </p>
              <p>
                <strong>Stage:</strong> {round.stage}
              </p>
            </div>
          ))}
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">Boards Übersicht</h3>
      <div className="space-y-4">
        {event.boards &&
          event.boards.map((board, index) => (
            <div
              key={index}
              className="border rounded-lg p-4 shadow-md bg-white"
            >
              <p>
                <strong>Board Beschreibung:</strong> {board.description}
              </p>
              <p>
                <strong>Status:</strong> {board.status}
              </p>
              <h4 className="text-lg font-medium mt-2">Zugewiesene Runden:</h4>
              {board.rounds && board.rounds.length > 0 ? (
                <ul className="list-disc list-inside">
                  {board.rounds.map((round, idx) => (
                    <li key={idx}>{round.description}</li>
                  ))}
                </ul>
              ) : (
                <p>Keine Runden zugewiesen</p>
              )}
            </div>
          ))}
      </div>

      {event.event_ready ? (
        <p className="mt-6 text-center text-green-600 font-semibold">
          Das Event wurde freigegeben und ist nicht mehr bearbeitbar.
        </p>
      ) : (
        <div className="mt-6 text-center">
          {event.teams_completed && event.status === "new" ? (
            <button
              onClick={releaseEvent}
              className="mt-4 bg-green-500 text-white px-6 py-2 rounded"
            >
              Event freigeben
            </button>
          ) : (
            <p className="text-red-600 font-semibold">
              Die Teams sind noch nicht freigegeben.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default EventEdit;
