import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useLocation,
  matchPath,
} from "react-router-dom";
import TournamentManagement from "./components/TournamentManagement";
import TeamEdit from "./components/TeamEdit";
import EventEdit from "./components/EventEdit";
import BoardEdit from "./components/BoardEdit";
import TournamentBracket from "./components/TournamentSchedule";
import GameCalculator from "./components/GameCalculator";
import TournamentGroups from "./components/TournamentGroups";
import FastGameForm from "./components/FastGameForm";
import GameViewer from "./components/frontend/GameViewer";
import HomePage from "./components/frontend/HomePage";
import LiveGames from "./components/frontend/LiveGames";

// Header Component
function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-gray-800 text-white py-4 px-6">
      <nav className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Darts Tournament Hub</h1>
        {/* Mobile Menu Toggle */}
        <button
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          className="sm:hidden block text-white focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMobileMenuOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16m-7 6h7"
              }
            />
          </svg>
        </button>
        {/* Desktop Menu */}
        <ul className="hidden sm:flex space-x-6">
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? "text-yellow-400 font-semibold"
                  : "hover:text-yellow-300"
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/fastGame"
              className={({ isActive }) =>
                isActive
                  ? "text-yellow-400 font-semibold"
                  : "hover:text-yellow-300"
              }
            >
              Schnellspiel
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/event/1/liveGames"
              className={({ isActive }) =>
                isActive
                  ? "text-yellow-400 font-semibold"
                  : "hover:text-yellow-300"
              }
            >
              Live-Spiele
            </NavLink>
          </li>
        </ul>
      </nav>
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <ul className="sm:hidden mt-4 space-y-2">
          <li>
            <NavLink
              to="/home"
              className="block text-yellow-300 hover:text-yellow-500"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/fastGame"
              className="block text-yellow-300 hover:text-yellow-500"
            >
              Schnellspiel
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/event/1/liveGames"
              className="block text-yellow-300 hover:text-yellow-500"
            >
              Live-Spiele
            </NavLink>
          </li>
        </ul>
      )}
    </header>
  );
}

// App Component
function App() {
  const location = useLocation();

  // Liste der Pfade ohne Header
  const noHeaderPaths = ["/game/:gameId/gameCalculator", "/live/*"];

  // Überprüfen, ob der aktuelle Pfad mit einer der Ausnahmen übereinstimmt
  const hideHeader = noHeaderPaths.some((path) =>
    matchPath(path, location.pathname)
  );

  return (
    //prettier-ignore
    <div className="flex flex-col h-screen">
      {!hideHeader && <Header />}
      <main className="flex-1 overflow-auto p-">
        <Routes>
          <Route path="/home" element={<TournamentManagement />} />

          <Route path="/event/:eventId/eventEdit" element={<EventEdit />} />
          <Route path="/event/:eventId/teamedit" element={<TeamEdit />} />
          <Route path="/event/:eventId/boardEdit" element={<BoardEdit />} />
          <Route path="/event/:eventId/tournamentGroups" element={<TournamentGroups />} />
          <Route path="/event/:eventId/tournamentBracket" element={<TournamentBracket />} />

          <Route path="/game/:gameId/gameCalculator" element={<GameCalculator />} />

          <Route path="/live/game" element={<GameViewer />} />
          <Route path="/live/:eventId/liveGames" element={<LiveGames />} />
          <Route path="/live/event/:eventId" element={<HomePage />} />

          <Route path="/fastGame" element={<FastGameForm />} />
        </Routes>
      </main>
    </div>
  );
}

// App Component Wrapped with Router
function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Root;
