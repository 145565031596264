import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

function GameViewer({ gameId: propGameId, liveticker: propLiveticker }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();

  // Query-Params extrahieren
  const queryGameId = queryParams.get("gameId");
  const queryLiveticker = queryParams.get("liveticker") === "true"; // Boolean-Umwandlung

  // Props und Query-Params kombinieren
  const gameId = propGameId || queryGameId;
  const liveticker = propLiveticker ?? queryLiveticker; // Props haben Vorrang

  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);
  const [liveTickerData, setLiveTickerData] = useState([]);
  const [showLiveTicker, setShowLiveTicker] = useState(false);
  const [isGameDataLoaded, setIsGameDataLoaded] = useState(false);

  useEffect(() => {
    fetchInitialGameData();
  }, [gameId]);

  const fetchInitialGameData = async () => {
    try {
      const response = await fetch(
        `http://localhost:8000/games/${gameId}/details`
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Spieldaten");
      }
      const data = await response.json();
      setGameData(data);
      setIsGameDataLoaded(true);
    } catch (error) {
      console.error("Fehler beim Abrufen der Spieldaten:", error);
      setError("Fehler beim Abrufen der Spieldaten.");
    }
  };

  const fetchLivetickerGameData = async () => {
    try {
      const response = await fetch(
        `http://localhost:8000/all_scores/${gameId}`
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Liveticker-Daten");
      }
      const data = await response.json();
      setLiveTickerData(data.scores || []);
    } catch (error) {
      console.error("Fehler beim Abrufen der Liveticker-Daten:", error);
      setError("Fehler beim Abrufen der Liveticker-Daten.");
    }
  };

  useEffect(() => {
    if (!isGameDataLoaded) return; // Warte, bis die initialen Spieldaten geladen sind

    let socket;
    const connectWebSocket = () => {
      const wsUrl = `ws://localhost:8000/ws/game/${gameId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected for game:", gameId);
        setError(null);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.action === "reload") {
          fetchInitialGameData();
          return;
        }

        setGameData((prevData) => {
          const updatedData = {
            ...prevData,
            teams: {
              ...prevData.teams,
              [data.current_team_id]: {
                ...prevData.teams[data.current_team_id],
                score: data.current_team_score,
                current_player: {
                  id: data.next_player_id,
                  name: data.next_player_name,
                  check_mode: data.next_player_check_mode,
                  club_player: data.next_player_club_player,
                },
              },
            },
            current_team: data.next_team_id,
            leg_count: data.leg_count,
            winner_id: data.winner_id,
          };

          return updatedData;
        });

        if (data.leg_count > data.score_leg) {
          fetchInitialGameData();
        }
        if (data.winner_id) {
          fetchInitialGameData();
        }
        setLiveTickerData((prevTickerData) => [
          {
            team_id: data.teamId,
            score: data.score_subtracted,
            leg: data.score_leg,
            player_name: data.current_player_name,
            created_on: new Date().toISOString(),
          },
          ...prevTickerData,
        ]);
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
        setError("Verbindungsfehler. Bitte später erneut versuchen.");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [gameId, isGameDataLoaded]);

  const toggleLiveTicker = () => {
    setShowLiveTicker(!showLiveTicker);
    if (!showLiveTicker) {
      fetchLivetickerGameData();
    }
  };

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!gameData) {
    return <div className="text-center text-gray-500">Lädt Spieldaten...</div>;
  }

  // Generate interleaved ticker data
  const interleavedTickerData = [];
  liveTickerData.forEach((score, index) => {
    if (score.team_id === gameData.teams.team1.id) {
      interleavedTickerData.push([score, null]);
    } else {
      interleavedTickerData.push([null, score]);
    }
  });

  return (
    <div className=" flex justify-center m-2">
      <div className="p-4 w-full max-w-3xl bg-gray-900 text-white rounded-lg shadow-lg">
        {/* Current Score */}
        <div className="flex flex-col justify-center items-center mb-6 text-lg font-bold">
          <div className="text-sm text-gray-400">{gameData.game_id}</div>
          {gameData.winner_id && (
            <div className="text-green-600">Spiel beendet</div>
          )}
          <div>{gameData.round}</div>
          <div>{gameData.board == "Kein Board" ? "" : gameData.board}</div>
          <div>
            {gameData.teams.team1.name} {"  "} {gameData.teams.team1.legs_won} -{" "}
            {gameData.teams.team2.legs_won} {"  "} {gameData.teams.team2.name}
          </div>
        </div>

        {/* Team Panels */}
        <div className="flex flex-wrap gap-4 justify-center">
          {["team1", "team2"].map((teamKey) => (
            <div
              key={teamKey}
              className={`flex-1 px-2 py-4 rounded-lg border-4 min-w-[45%] ${
                gameData.current_team === teamKey
                  ? "border-green-500"
                  : "border-gray-700"
              }`}
            >
              <h3 className="text-xl font-bold mb-2 text-center uppercase">
                <span style={{ fontSize: "1.0em" }}>
                  {gameData.throw_on_team === teamKey && "•"}
                </span>
                {gameData.teams[teamKey].name}
              </h3>
              {gameData.teams[teamKey].current_player.name !=
                gameData.teams[teamKey].name && (
                <h3 className="text-ls font-bold mb-2 text-center uppercase">
                  {/* TODO: Vereinsspieler markieren */}
                  {/* <span style={{ fontSize: "1.0em" }}>
                  {gameData.throw_on_team === teamKey && "•"}
                </span>{" "} */}
                  {gameData.teams[teamKey].current_player.name}
                </h3>
              )}

              <div className="text-center text-3xl font-bold">
                {gameData.teams[teamKey].score}
              </div>
            </div>
          ))}
        </div>

        {/* Liveticker Button */}
        {liveticker ? (
          <button
            onClick={toggleLiveTicker}
            className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded w-full sm:w-auto"
          >
            {showLiveTicker ? "Liveticker ausblenden" : "Liveticker anzeigen"}
          </button>
        ) : (
          <button
            onClick={() =>
              navigate(`/live/game?gameId=${gameData.game_id}&liveticker=true`)
            }
            className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded w-full sm:w-auto"
          >
            Einzelspiel
          </button>
        )}

        {/* Live Ticker */}
        {showLiveTicker && (
          <div className="mt-6 bg-gray-800 p-4 rounded-lg shadow-inner max-h-64 overflow-y-auto">
            <h3 className="text-xl font-semibold mb-4 text-center">
              Liveticker
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {interleavedTickerData.map(([team1, team2], index) => (
                <React.Fragment key={index}>
                  <div>
                    {team1 && (
                      <div className="mb-2">
                        <span className="font-semibold">
                          {interleavedTickerData.length - index}. Leg{" "}
                          {team1.leg}:
                        </span>{" "}
                        {team1.player_name} scored {team1.score}
                      </div>
                    )}
                  </div>
                  <div>
                    {team2 && (
                      <div className="mb-2">
                        <span className="font-semibold">
                          {interleavedTickerData.length - index}. Leg{" "}
                          {team2.leg}:
                        </span>{" "}
                        {team2.player_name} scored {team2.score}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GameViewer;
