import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function BoardEdit() {
  const { eventId } = useParams();
  const [boards, setBoards] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [boardDescriptions, setBoardDescriptions] = useState({});

  useEffect(() => {
    fetchBoards();
    fetchRounds();
  }, [eventId]);

  const fetchBoards = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/boards`);
    if (response.ok) {
      const data = await response.json();

      const descriptions = {};
      const boardsWithRounds = await Promise.all(
        data.map(async (board) => {
          descriptions[board.id] = board.description;
          const roundsResponse = await fetch(
            `${BACKEND_URL}/boards/${board.id}/rounds`
          );
          const assignedRounds = roundsResponse.ok
            ? await roundsResponse.json()
            : [];
          return { ...board, assignedRounds: assignedRounds.map((r) => r.id) };
        })
      );

      setBoards(boardsWithRounds);
      setBoardDescriptions(descriptions);
    } else {
      console.error("Fehler beim Laden der Boards:", response.statusText);
    }
  };

  const fetchRounds = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/rounds/`);
    if (response.ok) {
      const data = await response.json();
      setRounds(data);
    } else {
      console.error("Fehler beim Laden der Runden:", response.statusText);
    }
  };

  const handleDescriptionChange = (boardId, value) => {
    setBoardDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [boardId]: value,
    }));
  };

  const handleRoundCheckboxChange = (boardId, roundId) => {
    setBoards((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId
          ? {
              ...board,
              assignedRounds: board.assignedRounds.includes(roundId)
                ? board.assignedRounds.filter((id) => id !== roundId)
                : [...board.assignedRounds, roundId],
            }
          : board
      )
    );
  };

  const checkAllRoundsForBoard = (boardId) => {
    setBoards((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId
          ? { ...board, assignedRounds: rounds.map((round) => round.id) }
          : board
      )
    );
  };

  const saveAllBoards = async () => {
    for (const board of boards) {
      // Beschreibung speichern
      const descriptionResponse = await fetch(
        `${BACKEND_URL}/boards/${board.id}/update_description`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            board_id: board.id,
            description: boardDescriptions[board.id],
          }),
        }
      );

      if (!descriptionResponse.ok) {
        console.error(
          `Fehler beim Speichern der Beschreibung für Board ${board.id}.`
        );
        continue;
      }

      // Runden zuweisen
      const roundsResponse = await fetch(
        `${BACKEND_URL}/boards/${board.id}/assign_rounds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ round_ids: board.assignedRounds }),
        }
      );

      if (!roundsResponse.ok) {
        console.error(`Fehler beim Zuweisen der Runden für Board ${board.id}.`);
        continue;
      }
    }

    alert("Alle Boards erfolgreich gespeichert!");
    fetchBoards(); // Daten neu laden, um sicherzustellen, dass sie aktuell sind
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Board-Bearbeitung</h2>
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Boards</h3>
        <ul className="space-y-4">
          {boards.map((board) => (
            <li key={board.id} className="p-4 bg-gray-200 rounded-lg space-y-4">
              <div className="flex items-center">
                <input
                  type="text"
                  value={boardDescriptions[board.id]}
                  onChange={(e) =>
                    handleDescriptionChange(board.id, e.target.value)
                  }
                />
              </div>
              <div className="space-y-2">
                <button
                  onClick={() => checkAllRoundsForBoard(board.id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                >
                  Alle Runden auswählen
                </button>
                <h4 className="text-lg font-medium">Runden:</h4>
                <ul className="space-y-2">
                  {rounds.map((round) => (
                    <li key={round.id} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={board.assignedRounds.includes(round.id)}
                        onChange={() =>
                          handleRoundCheckboxChange(board.id, round.id)
                        }
                        className="mr-2"
                      />
                      <label>{round.description}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <button
          onClick={saveAllBoards}
          className="bg-green-500 text-white px-4 py-2 rounded mt-4"
        >
          Alle Boards speichern
        </button>
      </div>
    </div>
  );
}

export default BoardEdit;
