import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";
import { useNavigate } from "react-router-dom";

function TournamentGames() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [event, setEvent] = useState(null);
  const [displayMode, setDisplayMode] = useState("order");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([fetchEvent(), fetchGames()]);
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, displayMode]);

  const fetchEvent = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}`);
      const data = await response.json();
      setEvent(data);
    } catch (error) {
      console.error("Fehler beim Abrufen des Events:", error);
    }
  };

  const fetchGames = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}/games`);
      if (response.ok) {
        const data = await response.json();

        if (displayMode === "order") {
          // Sortiere nach Spiel-ID
          const sortedGames = data.games.sort((a, b) => a.id - b.id);
          setGames(sortedGames);
        } else if (displayMode === "group") {
          // Gruppiere Spiele nach Gruppe oder Hauptrunde
          const groupedGames = data.games.reduce((acc, game) => {
            const groupName =
              game.round.stage === 0
                ? game.round.group || "Gruppe unbekannt"
                : "Hauptrunde";
            if (!acc[groupName]) {
              acc[groupName] = [];
            }
            acc[groupName].push(game);
            return acc;
          }, {});

          // Sortiere die Gruppen alphabetisch
          const sortedGroupedGames = Object.keys(groupedGames)
            .sort()
            .reduce((acc, key) => {
              acc[key] = groupedGames[key];
              return acc;
            }, {});

          setGames(sortedGroupedGames);
        }
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    }
  };

  const generateTournament = async () => {
    setIsGenerating(true);
    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/generate_schedule`,
      {
        method: "POST",
      }
    );

    if (response.ok) {
      alert("Turnierbaum erfolgreich erstellt!");
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
    setIsGenerating(false);
  };

  const startTournament = async () => {
    setIsGenerating(true);
    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/start_tournament`,
      {
        method: "POST",
      }
    );

    if (response.ok) {
      alert("Turnier gestartet");
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
    setIsGenerating(false);
  };

  const startMainRound = async () => {
    setIsGenerating(true);
    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/start_main_round`,
      {
        method: "POST",
      }
    );

    if (response.ok) {
      alert("Hauptrunde gestartet");
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
    setIsGenerating(false);
  };

  const startGame = async (gameId) => {
    const response = await fetch(`${BACKEND_URL}/games/${gameId}/start`, {
      method: "POST",
    });

    if (response.ok) {
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const endGame = async (gameId) => {
    const response = await fetch(`${BACKEND_URL}/games/${gameId}/end`, {
      method: "POST",
    });

    if (response.ok) {
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const deleteFastGame = async (gameId) => {
    const response = await fetch(
      `${BACKEND_URL}/game/fastgame/${gameId}/delete`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      fetchGames();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const handleDisplayModeChange = (mode) => {
    setGames([]); // Setze die Spiele zurück, bevor die Ansicht geändert wird
    setDisplayMode(mode);
    setLoading(true); // Ladeanzeige während des Ladens der neuen Daten
  };

  const GameCard = ({ game, navigate, fastGame = false }) => (
    <div className="flex-col items-center border rounded-lg p-4 bg-gray-100 shadow-md gap-1">
      <div className="flex-1 text-left">
        <span className="font-medium">Spiel {game.id}:</span> {game.team1} vs{" "}
        {game.team2}
      </div>
      {game.round.stage == 0 && (
        <div className="flex-1 text-left">
          <span className="font-medium">Gruppe: {game.round.group}:</span>
        </div>
      )}

      <div className="flex-1 text-left">
        <span className="font-medium">Runde:</span> {game.round.description}
        {game.round.stage === 0 && game.group ? ` - Gruppe: ${game.group}` : ""}
      </div>
      {!fastGame && (
        <div className="flex-1 text-left">
          <span className="font-medium">Board:</span>{" "}
          {game.board.description || "Noch nicht zugewiesen"}
        </div>
      )}

      <div className="flex-1 text-left">
        <span className="font-medium">Status:</span> {game.status || ""}
      </div>
      {game.winner && (
        <div className="flex-1 text-left">
          <span className="font-medium">Gewinner:</span> {game.winner || ""}
        </div>
      )}

      <div className="flex-1 text-right">
        {(game.status === "bereit" || game.status === "aktiv") && (
          <button
            onClick={() => navigate(`/game/${game.id}/gameCalculator`)}
            className="bg-green-500 text-white px-4 py-1 rounded"
          >
            Zum Spiel
          </button>
        )}
        {game.status === "beendet" && (
          <div className="w">
            <button
              onClick={() => navigate(`/game/${game.id}/gameCalculator`)}
              className="bg-orange-500 text-white px-4 py-1 rounded"
            >
              Zum Spiel
            </button>
            {eventId == 1 && (
              <button
                onClick={() => deleteFastGame(game.id)}
                className="bg-red-500 text-white px-4 py-1 rounded ml-2"
              >
                Löschen
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (loading) return <div>Lädt...</div>;

  const FastGame = () => {
    return (
      <div className="p-4">
        <h2 className="text-2xl font-semibold mb-4">Schnelles Spiel</h2>
        <div className="space-y-4">
          {displayMode === "order" && Array.isArray(games) && (
            <>
              {[...games].reverse().map((game) => (
                <GameCard
                  key={game.id}
                  game={game}
                  navigate={navigate}
                  fastGame={true}
                />
              ))}
            </>
          )}
        </div>
      </div>
    );
  };

  return eventId == 1 ? (
    <FastGame></FastGame>
  ) : (
    <div className="p-4">
      {event.status === "new" ? (
        <h2 className="text-2xl font-semibold mb-4">
          Turnier noch nicht freigegeben
        </h2>
      ) : (
        <>
          {isGenerating ? (
            <p>Turnierbaum wird generiert...</p>
          ) : (
            <div className="mb-4">
              {event?.status === "released" && (
                <button
                  onClick={generateTournament}
                  className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                >
                  Turnierbaum generieren
                </button>
              )}
              {event?.status === "ready" && (
                <button
                  onClick={startTournament}
                  className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                >
                  Turnier starten
                </button>
              )}
              {event?.status === "group_finished" && (
                <button
                  onClick={startMainRound}
                  className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                >
                  Hauptrunde starten
                </button>
              )}
            </div>
          )}

          {(event?.status === "Gruppenphase" ||
            event?.status === "Mainround" ||
            event?.status === "group_finished") && (
            <div className="space-y-4">
              <h2 className="text-2xl font-semibold mb-4">Turnierbaum</h2>
              <div className="mb-4">
                <button
                  onClick={() => handleDisplayModeChange("order")}
                  className={`px-4 py-2 mr-2 rounded w-40 ${
                    displayMode === "order"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  Nach Reihenfolge
                </button>
                <button
                  onClick={() => handleDisplayModeChange("group")}
                  className={`px-4 py-2 rounded w-40 ${
                    displayMode === "group"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  Nach Gruppe
                </button>
              </div>
              {displayMode === "order" && Array.isArray(games) ? (
                <>
                  <h3 className="text-xl font-semibold mt-4">Gruppenphase</h3>
                  {games
                    .filter((game) => game.round.stage === 0)
                    .map((game) => (
                      <GameCard key={game.id} game={game} navigate={navigate} />
                    ))}
                  <h3 className="text-xl font-semibold mt-4">Hauptrunde</h3>
                  {games
                    .filter((game) => game.round.stage > 0)
                    .map((game) => (
                      <GameCard key={game.id} game={game} navigate={navigate} />
                    ))}
                </>
              ) : (
                Object.keys(games).map((group) => (
                  <>
                    <h3 key={group} className="text-xl font-semibold mt-4">
                      {group}
                    </h3>
                    {games[group].map((game) => (
                      <GameCard key={game.id} game={game} navigate={navigate} />
                    ))}
                  </>
                ))
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TournamentGames;
