import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { BACKEND_URL } from "../config";

Modal.setAppElement("#root");

function TournamentManagement() {
  const [tournaments, setTournaments] = useState([]);
  const [modes, setModes] = useState([]); // Modi aus dem Backend speichern
  const [newTournament, setNewTournament] = useState({
    name: "",
    date: "",
    group_count: "",
    mainround_count: "",
    group_team_count: "",
    team_player_count: "",
    allow_club_players: false,
    max_club_players: 0,
    board_count: "",
    rounds: [],
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [roundSteps, setRoundSteps] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTournaments();
    fetchModes(); // Modi laden
  }, []);

  const fetchTournaments = async () => {
    const response = await fetch(`${BACKEND_URL}/events/`);
    const data = await response.json();
    setTournaments(data);
  };

  const fetchModes = async () => {
    const response = await fetch(`${BACKEND_URL}/modes/`);
    const data = await response.json();
    setModes(data);
  };

  const openModal = () => {
    setModalIsOpen(true);
    setCurrentStep(1);
  };

  const closeModal = () => setModalIsOpen(false);

  const nextStep = () => {
    console.log(newTournament);
    if (currentStep === 1 && (!newTournament.name || !newTournament.date)) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }
    if (
      currentStep === 2 &&
      (!newTournament.group_count ||
        !newTournament.group_team_count ||
        !newTournament.team_player_count ||
        (newTournament.allow_club_players && !newTournament.max_club_players))
    ) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }
    if (currentStep === 3 && !newTournament.mainround_count) {
      alert("Bitte geben Sie die Anzahl der Hauptrunden ein.");
      return;
    }
    if (currentStep === 4) {
      const currentRound = newTournament.rounds[roundSteps];
      if (!currentRound.legs || !currentRound.mode) {
        alert("Bitte füllen Sie alle Felder aus.");
        return;
      }
    }

    if (currentStep === 3) {
      const roundNames = [
        "Achtelfinale",
        "Viertelfinale",
        "Halbfinale",
        "Finale",
      ];
      const totalMainRounds = parseInt(newTournament.mainround_count) || 0;
      const mainRounds = Array(totalMainRounds)
        .fill()
        .map((_, i) => ({
          description:
            roundNames[roundNames.length - totalMainRounds + i] ||
            `Runde ${i + 1}`,
          legs: 0,
          mode: "",
          stage: i + 1,
        }));
      setNewTournament((prev) => ({
        ...prev,
        rounds: [
          { description: "Gruppenphase", legs: 0, mode: "", stage: 0 },
          ...mainRounds,
        ],
      }));
    }

    if (currentStep === 4 && roundSteps < newTournament.rounds.length - 1) {
      setRoundSteps(roundSteps + 1);
    } else {
      setCurrentStep(currentStep + 1);
      setRoundSteps(0);
    }
  };

  const prevStep = () => {
    if (currentStep === 4 && roundSteps > 0) {
      setRoundSteps(roundSteps - 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRoundChange = (field, value) => {
    const updatedRounds = [...newTournament.rounds];
    updatedRounds[roundSteps][field] = value;
    setNewTournament((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const handleSubmit = async () => {
    const response = await fetch(`${BACKEND_URL}/create_event/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTournament),
    });

    if (response.ok) {
      console.log("Turnier erfolgreich erstellt");
      fetchTournaments();
    } else {
      console.error("Fehler beim Erstellen des Turniers");
    }
    resetTournamentData();
    setModalIsOpen(false);
  };

  const resetTournamentData = () => {
    setNewTournament({
      name: "",
      date: "",
      group_count: "",
      mainround_count: "",
      group_team_count: "",
      team_player_count: "",
      allow_club_players: false,
      max_club_players: "",
      rounds: [],
    });
  };

  const handleDeleteEvent = async (eventId) => {
    const userConfirmed = window.confirm(
      "Möchtest du dieses Event wirklich löschen?"
    );

    if (userConfirmed) {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        setTournaments(
          tournaments.filter((tournament) => tournament.id !== eventId)
        );
        alert("Event wurde erfolgreich gelöscht.");
      } else {
        alert("Fehler beim Löschen des Events. Bitte versuche es erneut.");
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Turnierverwaltung</h2>
      <button
        onClick={openModal}
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mb-4 mr-3"
      >
        Turnier erstellen
      </button>
      <button
        onClick={() => navigate(`/fastGame`)}
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mb-4 mr-3"
      >
        Spiel erstellen
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Turnier erstellen"
        className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h3 className="text-lg font-medium mb-4">Turnier erstellen</h3>

        {/* Seite 1 - Basisinformationen */}
        {currentStep === 1 && (
          <>
            <input
              type="text"
              placeholder="Turniername"
              value={newTournament.name}
              onChange={(e) =>
                setNewTournament({ ...newTournament, name: e.target.value })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <input
              type="date"
              value={newTournament.date}
              onChange={(e) =>
                setNewTournament({ ...newTournament, date: e.target.value })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
          </>
        )}

        {/* Seite 2 - Gruppeneinstellungen */}
        {currentStep === 2 && (
          <>
            <input
              type="number"
              placeholder="Anzahl Gruppen"
              value={newTournament.group_count}
              onChange={(e) =>
                setNewTournament({
                  ...newTournament,
                  group_count: e.target.value,
                })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <input
              type="number"
              placeholder="Anzahl Teams pro Gruppe"
              value={newTournament.group_team_count}
              onChange={(e) =>
                setNewTournament({
                  ...newTournament,
                  group_team_count: e.target.value,
                })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <input
              type="number"
              placeholder="Anzahl Spieler pro Team"
              value={newTournament.team_player_count}
              onChange={(e) => {
                const teamPlayerCount = parseInt(e.target.value, 10);
                setNewTournament({
                  ...newTournament,
                  team_player_count: teamPlayerCount,
                });

                // Reset max_club_players if it exceeds team_player_count
                if (newTournament.max_club_players > teamPlayerCount) {
                  setNewTournament({
                    ...newTournament,
                    team_player_count: teamPlayerCount,
                    max_club_players: teamPlayerCount, // Begrenzen
                  });
                }
              }}
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <div className="flex items-center space-x-4">
              <label>
                <input
                  type="checkbox"
                  checked={newTournament.allow_club_players}
                  onChange={(e) =>
                    setNewTournament({
                      ...newTournament,
                      allow_club_players: e.target.checked,
                    })
                  }
                  className="mr-2"
                />
                Vereinsspieler erlaubt
              </label>
              {newTournament.allow_club_players && (
                <input
                  type="number"
                  placeholder="Maximale Vereinsspieler pro Team"
                  value={newTournament.max_club_players}
                  onChange={(e) => {
                    const maxClubPlayers =
                      e.target.value === "" ? "" : parseInt(e.target.value, 10);

                    // Überprüfen, ob das Feld leer ist oder der Wert gültig ist
                    if (
                      maxClubPlayers === "" ||
                      maxClubPlayers <= newTournament.team_player_count
                    ) {
                      setNewTournament({
                        ...newTournament,
                        max_club_players: maxClubPlayers,
                      });
                    } else {
                      alert(
                        "Maximale Vereinsspieler dürfen die Anzahl der Spieler pro Team nicht überschreiten."
                      );
                    }
                  }}
                  className="border border-gray-300 p-2 rounded mb-4 w-full"
                />
              )}
            </div>
          </>
        )}

        {/* Seite 3 - Hauptrundeneinstellungen */}
        {currentStep === 3 && (
          <>
            <input
              type="number"
              placeholder="Anzahl Hauptrunden"
              value={newTournament.mainround_count}
              onChange={(e) =>
                setNewTournament({
                  ...newTournament,
                  mainround_count: e.target.value,
                })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <input
              type="number"
              placeholder="Anzahl Boards"
              value={newTournament.board_count}
              onChange={(e) =>
                setNewTournament({
                  ...newTournament,
                  board_count: e.target.value,
                })
              }
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
          </>
        )}

        {/* Seite 4 - Dynamische Runden */}
        {currentStep === 4 && (
          <div>
            <h4 className="text-lg font-medium mb-2">
              {roundSteps === 0
                ? `Gruppenphase`
                : `Hauptrunde: ${newTournament.rounds[roundSteps]?.description}`}
            </h4>
            <input
              type="number"
              placeholder="Winning Legs"
              value={newTournament.rounds[roundSteps]?.legs || ""}
              onChange={(e) => handleRoundChange("legs", e.target.value)}
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <select
              value={newTournament.rounds[roundSteps]?.mode || ""}
              onChange={(e) => handleRoundChange("mode", e.target.value)}
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            >
              <option value="">Modus wählen</option>
              {modes.map((mode) => (
                <option key={mode.id} value={mode.id}>
                  {mode.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Zusammenfassungsseite */}
        {currentStep === 5 && (
          <>
            <h4 className="text-lg font-medium mb-2">Zusammenfassung</h4>
            <p>Turniername: {newTournament.name}</p>
            <p>Datum: {newTournament.date}</p>
            <p>Anzahl Gruppen: {newTournament.group_count}</p>
            <p>Anzahl Teams pro Gruppe: {newTournament.group_team_count}</p>
            <p>Anzahl Spieler pro Team: {newTournament.team_player_count}</p>
            <p>
              Vereinsspieler erlaubt:{" "}
              {newTournament.allow_club_players ? "Ja" : "Nein"}
            </p>
            {newTournament.allow_club_players && (
              <p>
                Maximale Vereinsspieler pro Team:{" "}
                {newTournament.max_club_players}
              </p>
            )}
            <h5 className="text-lg font-medium mt-4 mb-2">Runden</h5>
            {newTournament.rounds.map((round, index) => (
              <div key={index}>
                <p>{round.description}:</p>
                <p>Winning Legs: {round.legs}</p>
                <p>
                  {console.log(modes)}
                  {console.log(typeof modes[0].id)}
                  Modus:{" "}
                  {modes.find((mode) => Number(mode.id) === Number(round.mode))
                    ?.name || "Modus nicht gefunden"}
                </p>
              </div>
            ))}
          </>
        )}

        <div className="flex justify-between mt-4">
          {currentStep > 1 && (
            <button
              type="button"
              onClick={prevStep}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            >
              Zurück
            </button>
          )}
          {currentStep < 5 && (
            <button
              type="button"
              onClick={nextStep}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
            >
              Weiter
            </button>
          )}
          {currentStep == 5 && (
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
            >
              Turnier erstellen
            </button>
          )}
        </div>
        <button
          onClick={closeModal}
          className="mt-4 text-red-500 hover:underline"
        >
          Schließen
        </button>
      </Modal>

      {/* Bestehende Turniere */}
      <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-800">
        Bestehende Turniere
      </h3>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {tournaments.map((tournament) => (
          <div
            key={tournament.id}
            className="p-6 bg-white shadow-md rounded-lg border border-gray-200"
          >
            <h4 className="text-lg font-bold text-gray-800 mb-2">
              {tournament.id === 1 ? "Schnelles Spiel" : tournament.name}
            </h4>
            {tournament.id && (
              <p className="text-sm text-gray-500">
                {tournament.id === 1 ? "" : "ID: " + tournament.id}
              </p>
            )}
            {tournament.date && (
              <p className="text-sm text-gray-500">{tournament.date}</p>
            )}

            <div className="mt-4 grid grid-cols-2 gap-2">
              {tournament.id === 1 ? (
                <>
                  <button
                    onClick={() =>
                      navigate(`/event/${tournament.id}/tournamentBracket`)
                    }
                    className="w-full bg-purple-700 hover:bg-purple-800 text-white px-4 py-2 rounded text-center"
                  >
                    Spiele anzeigen
                  </button>
                  <button
                    onClick={() => navigate(`/live/event/${tournament.id}`)}
                    className="w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded text-center"
                  >
                    Live
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() =>
                      navigate(`/event/${tournament.id}/eventEdit`)
                    }
                    className="w-full bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded text-center"
                  >
                    Übersicht
                  </button>
                  <button
                    onClick={() => navigate(`/event/${tournament.id}/teamEdit`)}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-center"
                  >
                    Teams
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/event/${tournament.id}/boardEdit`)
                    }
                    className="w-full bg-orange-500 hover:bg-orange-700 text-white px-4 py-2 rounded text-center"
                  >
                    Boards
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/event/${tournament.id}/tournamentBracket`)
                    }
                    className="w-full bg-purple-700 hover:bg-purple-800 text-white px-4 py-2 rounded text-center"
                  >
                    Turnierbaum
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/event/${tournament.id}/tournamentGroups`)
                    }
                    className="w-full bg-pink-500 hover:bg-pink-600 text-white px-4 py-2 rounded text-center"
                  >
                    Gruppen
                  </button>
                  <button
                    onClick={() => navigate(`/live/event/${tournament.id}`)}
                    className="w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded text-center"
                  >
                    Live
                  </button>
                  <button
                    onClick={() => handleDeleteEvent(tournament.id)}
                    className="w-full bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-center"
                  >
                    Löschen
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TournamentManagement;
