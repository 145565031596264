import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function TournamentGroups() {
  const { eventId } = useParams();
  const [groupTables, setGroupTables] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("Alle Gruppen");
  const [isExpandedView, setIsExpandedView] = useState(false); // Ansichtstoggle

  useEffect(() => {
    fetchGroupTables();
  }, [eventId]);

  const fetchGroupTables = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/groupsRanking`
      );
      if (response.ok) {
        const data = await response.json();
        setGroupTables(data["group_tables"] || {});
      } else {
        console.error(
          "Fehler beim Abrufen der Gruppentabellen:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Gruppentabellen:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="text-center p-6">Lädt...</div>;

  if (!groupTables || Object.keys(groupTables).length === 0) {
    return (
      <div className="text-center p-6">Keine Gruppentabellen verfügbar</div>
    );
  }

  // Gruppen filtern
  const filteredGroups =
    selectedGroup === "Alle Gruppen"
      ? Object.keys(groupTables)
      : [selectedGroup];

  return (
    <div className="p-4 pt-20">
      <h2 className="text-3xl font-bold text-center mb-6">
        Turnier Gruppentabellen
      </h2>

      {/* Toggle für Ansicht */}
      <div className="flex justify-between items-center mb-6 max-w-md mx-auto">
        {/* Gruppenfilter */}
        <select
          className="p-2 border border-gray-300 rounded w-full"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
        >
          <option value="Alle Gruppen">Alle Gruppen</option>
          {Object.keys(groupTables).map((groupKey) => (
            <option key={groupKey} value={groupKey}>
              {groupTables[groupKey].group_description}
            </option>
          ))}
        </select>

        {/* Ansicht-Umschalter */}
        <label className="flex items-center space-x-2 ml-4">
          <span className="text-lg">Erweiterte Ansicht</span>
          <input
            type="checkbox"
            className="toggle-checkbox"
            checked={isExpandedView}
            onChange={() => setIsExpandedView(!isExpandedView)}
          />
        </label>
      </div>

      {/* Normale Ansicht */}
      {!isExpandedView && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredGroups.map((groupKey) => (
            <div
              key={groupKey}
              className="bg-white shadow-lg rounded-lg p-4 border border-gray-200"
            >
              <h3 className="text-xl font-semibold text-center mb-4">
                {groupTables[groupKey].group_description}
              </h3>
              <ul className="space-y-2">
                {groupTables[groupKey].group_data.map((teamData, index) => (
                  <li
                    key={index}
                    className="p-4 border rounded-lg hover:bg-gray-100 flex justify-between items-center"
                  >
                    <span className="font-medium">{teamData.Team}</span>
                    <span className="text-lg font-semibold text-blue-500">
                      {teamData.Punkte} Pkt
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {/* Erweiterte Ansicht */}
      {isExpandedView && (
        <div>
          {filteredGroups.map((groupKey) => (
            <div key={groupKey} className="mb-6">
              <h3 className="text-xl font-bold mb-4">
                {groupTables[groupKey].group_description}
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">#</th>
                      <th className="px-4 py-2 border">Team</th>
                      <th className="px-4 py-2 border">Spiele gespielt</th>
                      <th className="px-4 py-2 border">Spiele gewonnen</th>
                      <th className="px-4 py-2 border">Spiele verloren</th>
                      <th className="px-4 py-2 border">Legs gewonnen</th>
                      <th className="px-4 py-2 border">Legs verloren</th>
                      <th className="px-4 py-2 border">Punkte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupTables[groupKey].group_data.map((teamData, index) => (
                      <tr key={index} className="text-center">
                        <td className="px-4 py-2 border">
                          {teamData.Platzierung}
                        </td>
                        <td className="px-4 py-2 border">{teamData.Team}</td>
                        <td className="px-4 py-2 border">
                          {teamData["Spiele gespielt"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {teamData["Spiele gewonnen"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {teamData["Spiele verloren"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {teamData["Legs gewonnen"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {teamData["Legs verloren"]}
                        </td>
                        <td className="px-4 py-2 border">{teamData.Punkte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TournamentGroups;
